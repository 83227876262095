<template>
  <div>
    <v-file-input
      v-model="file"
      :loading="isUploading"
      :disabled="disabled || isUploading"
      color="action"
      :accept="accept"
      :label="text"
      :error="errorMessage && errorMessage.length > 0"
      :error-messages="errorMessage"
      :validate-on-blur="true"
      append-icon="mdi-file-upload-outline"
      prepend-icon=""
      filled
      @change="onFileChange(file)"
      :show-size="1000"
    >
      <template v-slot:selection="{ index, text }">
        <v-chip v-if="index < 2" color="action" dark label small>
          {{ text }}
        </v-chip>
      </template>
    </v-file-input>
  </div>
</template>

<script>
export default {
  name: "BaseFileUpload",
  props: {
    text: {
      type: String,
      default: "upload file"
    },
    accept: {
      type: String,
      default: "*"
    },
    onFileChange: {
      type: Function,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isUploading: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ""
    },
    /**
     * id is required property to resetting file value works properly.
     */
    id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      file: null
    };
  },
  mounted() {
    this.$root.$on(`${this.id}-reset-base-file-input`, this.resetFile);
  },
  beforeDestroy() {
    this.$root.$off(`${this.id}-reset-base-file-input`, this.resetFile);
  },
  methods: {
    resetFile() {
      this.file = null;
    }
  }
};
</script>
