<template>
  <v-container class="data-preview white text-center">
    <v-progress-linear indeterminate v-if="isLoading" />
    <config-upload
      v-else-if="!isFilePresent && !isLoading"
      @file-present="isFilePresent = true"
    />
  </v-container>
</template>

<script>
import ConfigUpload from "@/views/Contentr/views/ContentrUpload/components/ConfigUpload";
export default {
  name: "Index",
  components: { ConfigUpload },
  data() {
    return {
      isFilePresent: false,
      isLoading: false
    };
  }
};
</script>
<style lang="scss">
.data-preview {
  border-radius: 6px;
  max-width: 1280px;
}
</style>
